import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout headerTitle="404: Not found" headerText="You just hit a route that doesn&#39;t exist...">
    <SEO title="404: Not found" />

  </Layout>
)

export default NotFoundPage
